import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from "../../views/users/users.module.scss"
import { FaSearch } from "react-icons/fa";
import axios from 'axios';
import { API_APP_ENABLEDISABLEUSER, UNIVERSAL_USER_SEARCH } from '../../utils/consts/api';
import { toast } from 'react-toastify';
import AgTable from './AgTable';
import { mobileUsers } from './demoData';
import SkeletonLoader from '../Loader';
import  "../customModal.scss";


function UniversalSearchTable (props) {

  
  let {token  , handleImageErr, handleViewFunc, handleEditFunc, handleDeleteOpen}=props;

  const [state,setState]=useState({
    disabled:false,
    spinner:false,
    show:false,
    search:'',
    userList:[]
  })
  const handleClose = () => {

    setState((prev)=>{
        return {...prev , userList:[],show:false}
    })
  }

  const handleShow = () => {
    if(state.search.length){

   
    setState((prev)=>{
        return {...prev , show:true}
    })
      handleUserSearchByMobile();
    }else{
      toast.error("Input is Empty", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    }
    
  };


  const handleUserSearchByMobile= async()=>{
    // setSpinner(true);
    setState((prev)=>{
        return {...prev, spinner:true}
    })
    try {
      const headers = {
          'Content-Type': 'application/json',
          authorization:token?token:""
      };
      const res = await axios.post(UNIVERSAL_USER_SEARCH, { mobileNumber:state.search }, { headers });
      if (res.data.responseCode === 0) {
        
        //  setUserList(res.data.response.agentDetails);
         setState((prev)=>{
            return {...prev, userList: res.data.response.agentDetails}
         })
        //  setSpinner(false);
         setState((prev)=>{
            return {...prev, spinner:false}
        })
      } else {
        // setSpinner(false);
        setState((prev)=>{
            return {...prev, spinner:false}
        })
          toast.error(res.data.errorMsg, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
      }
  } catch (err) {
    setState((prev)=>{
        return {...prev, spinner:false}
    })
      toast.error('Internal server error.', {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
  }
  }


  
  const handleEnableDisable = async (data) => {
        // setDisabled(true);
        setState((prev)=>{
            return {...prev, disabled:true}
        })
    try {
        const headers = {
            'Content-Type': 'application/json',
            authorization: token
        };
        
        const res = await axios.post(API_APP_ENABLEDISABLEUSER, { accountId: data.agentId, enabledStatus: data.enabledStatus===0 ? 1 : 0 }, { headers });
        if (res.data.responseCode === 0) {
            const status=res.data.response.currentStatus;
            toggleUserAction(data.agentId,status)
           
            setState((prev)=>{
                return {...prev, spinner:false , disabled:false}
            })
            toast.success(res.data.successMsg, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
           
           
            // resetCache();
        } else {
            // setDisabled(false);
            setState((prev)=>{
                return {...prev, spinner:false , disabled:false}
            })
            toast.error(res.data.errorMsg, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    } catch (err) {
        setState((prev)=>{
            return {...prev, spinner:false ,disabled:false}
        })
        toast.error('Internal server error.', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}


const toggleUserAction=(agentId,status)=>{
        
  
  let newData=state.userList.map((ele)=>{
      if(ele.agentId===agentId){
      
       ele.enabledStatus=status;
      }
      return ele;
  })

//   setUserList(newData);
  setState((prev)=>{
    return {...prev, userList:newData}
})
}


const handleKeyEnter=(e)=>{
   
    if(e.key==="Enter"){
        handleShow();
    }
}

  useEffect(()=>{
   
  },[]);
  
  return (
    <>
    
    

     <div className={`${styles.searchContainer}`}>
       <input className={`${styles.searchBar}`} type="text" 
       onChange={(e)=>setState((prev)=>{
     return {...prev , search:e.target.value}
       })}
       onKeyDown={handleKeyEnter}
      
       placeholder="Search by mobile number"value={state.search}/>
        <button className={`${styles.searchButton}`} disabled={state.disabled}  onClick={handleShow}  >
        <FaSearch className={`${styles.searchIcon}`} />
        </button>
     </div>
     
     
<div className='my-search-modal'>
<Modal show={state.show} onHide={handleClose} className='modal_popup'>
    <Modal.Header closeButton>
        <Modal.Title>User List</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {state.spinner && state.userList.length === 0 ? (
            <SkeletonLoader height={"300px"} />
        ) : (
            <AgTable
                section={"MobileUSers"}
                rowData={state.userList}
                colDefs={mobileUsers}
                handleImageErr={handleImageErr}
                handleViewFunc={handleViewFunc}
                handleEditFunc={handleEditFunc}
                handleDeleteOpen={handleEnableDisable}
                toggleStatus={state.disabled}
            />
        )}
    </Modal.Body>
</Modal>
</div>
    </>
  );
}

export default UniversalSearchTable;