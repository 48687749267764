export const HOST = "https://api-tiq.taxi-sanchalak.com/v1" //'http://43.204.122.47:9001/v1';    //b2b=> https://api.taxi-sanchalak.com/v1'   // b2c=> https://api-tiq.taxi-sanchalak.com/v1

export const EMAIL_REGEX = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
export const URL_REGEX = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
export const IsNum_REGEX = /^[0-9]*$/

export const API_LOGIN_USER = `${HOST}/admin/adminLogin`;
export const API_FORGET_PASSWORD = `${HOST}/admin/forgotPassword`;
export const API_AGENT_LIST = `${HOST}/admin/getAllAgent`;
export const API_SUPPORT_LIST = `${HOST}/admin/getSupportTicket`;
export const API_RESPOND_TICKET = `${HOST}/admin/respondTicket`;
export const API_RESPONDB2CTICKET_TICKET = `${HOST}/admin/respondB2CTicket`;
export const API_B2C_SUPPORT_LIST = `${HOST}/admin/getB2CRaiseTickets`;
export const API_VEHICLE_LIST = `${HOST}/admin/getAllVehicleType`;
export const API_VEHICLE_DELETE = `${HOST}/admin/deleteVehicleType`;
export const API_VEHICLE_ADD = `${HOST}/admin/addVehicleType`;
export const API_SUGGESTION_LIST = `${HOST}/admin/getSuggestions`;
export const API_EXPORT_LIST = `${HOST}/admin/getExportData`;
export const API_USER_DRIVER_DETAILS = `${HOST}/admin/getAgentDriver`;
export const API_USER_VEHICLE_DETAILS = `${HOST}/admin/getAgentVehicle`;
export const API_DELETE_AGENT = `${HOST}/admin/deleteAgent`;
export const API_UPDATE_AGENT = `${HOST}/admin/updateAgentInfo`;
export const API_UPDATE_AGENT_PROFILE = `${HOST}/admin/changeAgentPic`;
export const API_DELETE_DRIVER = `${HOST}/admin/deleteDriver`;
export const API_DELETE_VEHICLE = `${HOST}/admin/deleteVehicle`;
export const API_GET_DASHBOARD = `${HOST}/admin/getDashboard`;
export const API_NUMBER_UPDATE = `${HOST}/admin/editAdmin`;
export const API_ADD_DRIVER = `${HOST}/admin/addDriver`;
export const API_ADD_VEHICLE = `${HOST}/admin/addVehicle`;
export const API_EDIT_DRIVER = `${HOST}/admin/updateDriver`;
export const API_EDIT_VEHICLE = `${HOST}/admin/updateVehicle`;
export const API_CHANGE_PASSWORD = `${HOST}/admin/changePassword`;
export const API_APP_CONTENT_BANNER_DELETE = `${HOST}/admin/deleteHomeBanner`;
export const API_APP_CONTENT_ADDHOMEBANNER = `${HOST}/admin/addHomeBanner`;
export const API_APP_CONTENT_GETHOMEBANNER = `${HOST}/admin/getHomeBanner`;
export const API_APP_GET_SUBSCRIPTION = `${HOST}/admin/getSubscriptions`;
export const API_APP_ADD_SUBSCRIPTION = `${HOST}/admin/addSubscription`;
export const API_APP_UPDATE_SUBSCRIPTION = `${HOST}/admin/updateSubscription`;
export const API_APP_DELETE_SUBSCRIPTION = `${HOST}/admin/deleteSubscription`;
export const API_APP_GET_PaymentInfo = `${HOST}/admin/getPaymentInfo`;
export const API_APP_MOVE_PAYMENTONHOLD = `${HOST}/admin/movePaymentToHold`;
export const API_APP_MOVE_PAYMENTOPAID = `${HOST}/admin/movePaymentToPaid`;
export const API_APP_GENRATEREPORT = `${HOST}/admin/generateReport`;
export const API_APP_ENABLEDISABLEUSER = `${HOST}/admin/enableDisableUser`;
export const API_APP_CUSTOMNOTIFICATIONSEND = `${HOST}/admin/customNotificationSend`;
export const API_APP_ANNUALUSERLIST = `${HOST}/admin/getAllAnualUsers`;
export const API_APP_MONTHLYUSERLIST = `${HOST}/admin/getAllmonthlyUsers`;
export const API_APP_FREEUSERLIST = `${HOST}/admin/getAllFreeUsers`;
export const API_CRM_GETALLCRMUSERS = `${HOST}/admin/getAllCrmUsers`;
export const API_CRM_GENRATECRMREPORT = `${HOST}/admin/generateReportCRM`;
export const API_CRM_DELETECRMAGENT = `${HOST}/admin/deleteCRMAgent`;
export const API_CRM_UPDATECRMUSERINFO = `${HOST}/admin/updateCRMUserInfo`;
export const API_CRM_GETAGENTDRIVERCRM = `${HOST}/admin/getAgentDriverCRM`;
export const API_CRM_GETAGENTVEHICLECRM = `${HOST}/admin/getAgentVehicleCRM`;
export const API_CRM_GETMYCUTOMER = `${HOST}/admin/getMyCustomer`;
export const API_CRM_UPDATECUSTOMER = `${HOST}/admin/updateCustomer`;
export const API_CRM_ADDCUSTOMER = `${HOST}/admin/addCustomer`;
export const API_CRM_DELETECUSTOMER = `${HOST}/admin/deleteCustomer`;
export const API_ALL_SECURITY_WALLET_AGENT = `${HOST}/admin/allsecurityWalletagents`;
export const API_POPUP_NOTIFICATION = `${HOST}/admin/sendPopupNotification`;

export const API_GENERATE_REPORT_VEHICLE = `${HOST}/admin/generateReportVehicle`;
export const API_ALL_BOOKING_DETAILS = `${HOST}/admin/getAllBooking`;
export const API_EXPORT_BOOKING_DETAILS = `${HOST}/admin/generateBookingReport`;
export const API_FREE_USERS_DETAILS = `${HOST}/admin/getFreeMobileSubscriptionUsers`;
export const API_FETCH_DROPDOWN_FILTER = `${HOST}/admin/getMobileSubscription`;
export const API_PREMIUM_USERS_DETAILS = `${HOST}/admin/getPremiumMobileSubscriptionUsers`;
export const API_APP_POST_ADDEDITCOMMENTDATA = `${HOST}/admin/addComments`;



export const AUTO_SUBSCRIPTION_USER=`${HOST}/admin/autoSubscription`;
export const UNIVERSAL_USER_SEARCH=`${HOST}/admin/universalUserSearch`

// admin/enableDisableUser