import React from 'react';
import styles from "../../../views/B2C Payments/payment.module.scss";
import {OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiPhone } from 'react-icons/bi';
import { MdOutlineInfo } from 'react-icons/md';


const PostAgentProfileB2C = (props) => {
  
    return (
        <>
         <div className="d-flex w-100 align-items-center">
                                  <div>
                                    <img
                                      src={props.data.actualData.postedAgentImage||''}
                                      onError={props.context.handleImageErr}
                                      alt=""
                                      width="38"
                                      height="38"
                                      style={{ borderRadius: "50%" }}
                                    />
                                  </div>
                                  <div className="ms-2">
                                    {props.data.actualData.postedFullName ? <OverlayTrigger
                                      key={'left'}
                                      placement='left'
                                      overlay={
                                        <Tooltip id={`tooltip-left`}>
                                          {props.data.actualData.postedFullName}
                                        </Tooltip>
                                      }
                                    >
                                      <h6 className="m-0 bodyTextEllipsis" style={{ width: '120px' }}>
                                        {props.data.actualData.postedFullName}
                                      </h6>
                                    </OverlayTrigger> : <h6 className="m-0">-</h6>}                       
                                    <OverlayTrigger
                                      key={'right'}
                                      delay={{ show: 250, hide: 400 }}
                                      placement='right'
                                      className="testing"
                                      overlay={
                                        <Tooltip id={`tooltip-left`}>
                                          <div >
                                             <div className={styles.infoOverlayDiv}>
                                               <div >Agent : </div>
                                               <div ><BiPhone /> +919258196242 </div>
                                             </div>
                                             <div className={styles.infoOverlayDiv}>
                                               <div >Customer : </div>
                                               <div >{props.data.customerMobileNumber ? <span><BiPhone />+91-{props.data.customerMobileNumber}</span>  : '-'}</div>
                                             </div>
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                    <span>Info <MdOutlineInfo /></span>
                                    </OverlayTrigger>
                                  </div>
                                </div>
        </>
    );
}

export default PostAgentProfileB2C;
